@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  transition: 300ms ease-in-out;
}

html {
  scroll-behavior: smooth;
}


