.fave {
  animation: fave 2s ease-out;
}
@keyframes fave {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

::-webkit-calendar-picker-indicator {
  background-color: grey;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.scale {
  animation: scale 0.6s ease-out;
}
@keyframes scale {
  0% {
    transform: scale(0.2, 0.2);
  }
  80% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}
.slide {
  -webkit-animation: slide 0.8s ease-out;
  -moz-animation: slide 0.8s ease-out;
  -o-animation: slide 0.8s ease-out;
  animation: slide 0.8s ease-out;
}

@-webkit-keyframes slide {
  0% {
    -webkit-transform: translateX(200px);
    transform: translateX(200px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-moz-keyframes slide {
  0% {
    -moz-transform: translateX(200px);
    transform: translateX(200px);
  }
  100% {
    -moz-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-o-keyframes slide {
  0% {
    -o-transform: translateX(200px);
    transform: translateX(200px);
  }
  100% {
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes slide {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0px);
  }
}

.slideR {
  -webkit-animation: slideR 0.8s ease-out;
  -moz-animation: slideR 0.8s ease-out;
  -o-animation: slideR 0.8s ease-out;
  animation: slideR 0.8s ease-out;
}

@-webkit-keyframes slideR {
  0% {
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-moz-keyframes slideR {
  0% {
    -moz-transform: translateX(-200px);
    transform: translateX(-200px);
  }
  100% {
    -moz-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-o-keyframes slideR {
  0% {
    -o-transform: translateX(-200px);
    transform: translateX(-200px);
  }
  100% {
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes slideR {
  0% {
    transform: translateX(-200px);
  }
  100% {
    transform: translateX(0px);
  }
}

.appear {
  -webkit-animation: appear 0.8s ease-out;
  -moz-animation: appear 0.8s ease-out;
  -o-animation: appear 0.8s ease-out;
  animation: appear 0.8s ease-out;
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slideD {
  animation: slideD 0.5s ease-out;
}
@keyframes slideD {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0px);
  }
}
.slideDD {
  animation: slideD 0.3s ease-out;
}
@keyframes slideD {
  0% {
    opacity: 0.3;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
/* Track */
::-webkit-scrollbar {
  width: 8px;
  height: 3px;
}
@media (max-width: 1150px) {
  ::-webkit-scrollbar {
    width: 4px;
    height: 3px;
  }
}

.rotate {
  animation: rotation 0.4s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(170, 170, 170, 0.5);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(170, 170, 170, 0, 9);
}

input::placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}
table *:not(tr:first-child) {
  padding: 5px;
}
.table2 *:not(:first-child) {
  padding: 10px 30px;
}
.table2 *:not(tr:first-child) {
  margin-right: 30px;
}

.table2 *:not(td:first-child) {
  /* background-color: red; */
  margin-right: 5px;
}

table.table2 tr td.new-row {
  font-weight: medium;
  padding: 5px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  position: absolute;
  left: 0;
  bottom: -60px;
}

table.table2 tr td.new-row p {
  padding: 5px;
  line-height: 6px;
}
table.table2 tr td.new-row2 {
  font-weight: medium;
  padding: 5px;
  width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: -30px;
}

table.table2 tr td.new-row2 span {
  padding: 5px;
  line-height: 6px;
}

table.table2 tr td img {
  padding: 0 !important;
  width: 30px !important;
  height: 15px !important;
}

table.table2 tr td img.note {
  padding: 0 !important;
  width: 20px !important;
  height: 20px !important;
}

.pag a {
  padding: 10px;
  height: fit-content;
}

table tbody tr.mod {
  padding-top: 100px !important;
}

table tbody tr.mod > .box {
  padding: 20px !important;
}

table tbody tr.mod > .box > div > div {
  padding: 10px !important;
}

.dragging {
  transform: translateY(-8px); /* Adjust the value as needed */
  transition: transform 0.3s ease-in-out;
  animation: slideD 0.3s ease-out;
  /* background-color: rgba(148, 164, 253, 0.5); */
}

.fade {
  animation: fade 0.8s ease-in-out;
}
@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

input[type="range"] {
  width: 100%;
  height: 30px;
  -webkit-appearance: none;
  background-color: #c6c7f8;
  outline: none;
  opacity: 0.8;
  transition: opacity 0.2s;
  border-radius: 7px;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 30px;
  width: 20px;
  background: #ffffff;
  box-shadow: 0 0 5px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.note {
  padding: 10px !important;
}

.saveTax {
  padding: 0 8px !important;
  width: fit-content !important;
  height: fit-content !important;
}

.stickyDiv {
  /* Your default styles */
  transition: all 0.3s ease-in-out; /* Add a transition for smoothness */
}

.sticky {
  position: fixed;
  top: 80px;
  right: 0;
  width: 100%;
  font-size: 0.9em;
  padding-bottom: 5px;
  padding-left: 280px;
  z-index: 2;
  background-color: #1c1c1c;
}

/* Tablet styles */
@media (max-width: 1024px) {
  .sticky {
    position: fixed;
    top: 76px; /* Adjust this value to change the distance from the top */
    right: 0;
    width: 100%; /* Inherit width from the parent container */
    font-size: 0.9em; /* Inherit font-size from the parent container */
    padding-bottom: 5px; /* Inherit padding-bottom from the parent container */
    z-index: 100;
    background-color: #1c1c1c;
    padding-left: 230px; /* Adjust padding-left for tablets */
  }
}

/* Mobile styles */
@media (max-width: 640px) {
  .sticky {
    position: fixed;
    top: 65px; /* Adjust this value to change the distance from the top */
    right: 0;
    width: 100%; /* Inherit width from the parent container */
    font-size: 0.9em; /* Inherit font-size from the parent container */
    padding-bottom: 5px; /* Inherit padding-bottom from the parent container */
    padding-left: 20px; /* Adjust padding-left for mobile */
    z-index: 100;
    background-color: #1c1c1c;
  }
}
