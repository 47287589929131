.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.checkbox-container input[type="checkbox"] {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 32px;
  height: 16px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.8);
  outline: none;
  margin-right: 5px;
}
.checkbox-container input[type="checkbox"]:checked {
  border: 1px solid #94a4fd;
  background-color: #94a4fd;
}

.checkbox-container input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: #0c0c0d;
  border-radius: 20px;
  transform: translate(-50%, -50%) translateX(-6px);
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.checkbox-container input[type="checkbox"]:checked::before {
  width: 10px;
  height: 10px;
  transform: translate(-50%, -50%) translateX(4px);
  background-color: #0c0c0d;
  border-radius: 20px;
}

/* .checkbox-container input[type="checkbox"]:focus {
  background-color: #94a4fd;
}
.checkbox-container.checked input[type="checkbox"]:checked::before {
  background-color: #94a4fd;
} */
